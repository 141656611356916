import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../variables/api";
import { useParams } from "react-router";
import Plot from 'react-plotly.js';

import "./assessment.css"
import { min } from "moment";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import QuestionRender from "./QuestionRender";


const AssessmentAnalysis = () => {
  

  const [questions, setQuestions] = useState([]);
  const [Students, setStudents] = useState([]);
  const [StudentsQuestions, setStudentsQuestions] = useState([]);
  const [exam, setExam] = useState<any>({});
  const [chartData, setChartData] = useState<any>([]);
  const [selectedQuestion , setSelectedQuestion] = useState({})
  
  const [questionModal , setQuestionModal] = useState<any>(false);
  const [chartData2, setChartData2] = useState<any>([]);
  const { id }: any = useParams();
  const exam_id = id;
  useEffect(() => {
    Swal.showLoading()

    axios.get(`${BASE_URL}admin/analysis/getexamquestions.php?exam_id=${exam_id}`)
      .then(res => {
        let questions = res.data.data.questions;
        setStudents(res.data.data.students);
        setStudentsQuestions(res.data.data.student_questions);
        setQuestions(questions);
        setChartData([
          {
            x: questions.map((q: any, index: any) => `Q${index + 1}`),
            y: questions.map((q: any) => +q.correct_answers_count),
            type: 'bar',

            name: "NO. correct answers",
            mode: 'lines',
            line: { color: 'blue' },
          },
          {
            x: questions.map((q: any, index: any) => `Q${index + 1}`),
            y: questions.map((q: any) => +q.wrong_answers_count),
            type: 'bar',

            name: "NO. wrong answers",
            mode: 'lines',
            line: { color: 'red' },
          },

        ])
        setChartData2([

          {
            x: questions.map((q: any, index: any) => `Q${index + 1}`),
            y: questions.map((q: any) => +q.avr_time),
            type: 'bar',

            name: "Average duration (in seconds)",
            mode: 'lines',
            line: { color: 'red' },
          }
        ])
        setExam(res.data.data.exam);
        Swal.close();

      })
  }, [])

  const chart_layout_factory = (width: number) => {
    return {
      title: ' ',
      xaxis: { title: '', range: [0, null] },
      yaxis: { title: '', range: [0, null] },
      width,

      height: 500,
    }
  }
  const [chart_layout, setChart_layout] = useState(chart_layout_factory(1000));
  const handleShowAnswer = (question :any) =>{
    setSelectedQuestion(question);
    setQuestionModal(true);
  }
  return (
    <div>
      <h4 className="mb-5">Assessment Analysis : {exam?.name}</h4>


      <div className="row col-12 d-flex justify-content-center">

        <div className="text-center">
          <div className="mt-3 mb-3">NO. correct answers VS wrong answers</div>

          <Plot data={(chartData as any)} layout={(chart_layout as any)} config={{ scrollZoom: true, displaylogo: false, responsive: true }} />
        </div>
        <div className="text-center">
          <div className="mt-3 mb-3"> Average duration (in seconds)</div>
          <Plot data={(chartData2 as any)} layout={(chart_layout as any)} config={{ scrollZoom: true, displaylogo: false, responsive: true }} />
        </div>
      </div>




      {/* // Students questions  */}
      <div>
  <h3>Color Coding Explanation:</h3>
  <ul>
    <li><span style={{color :"red" , fontSize:"20px"}}>Red</span>: This color indicates that the solution is <strong>incorrect</strong>.</li>
    <li><span style={{color :"green" ,fontSize:"20px"}}>Green</span>: This color indicates that the solution is <strong>correct</strong>.</li>
    <li><span style={{color:"orange" , fontWeight:"bold" ,fontSize:"20px"}}>Orange</span>: This color indicates that the solution is <strong>partially correct</strong>.</li>
  </ul>
</div>

      <div className="table-responsive mb-5 mt-5" id="analysis-container">

        <table id="" className="table table-bordered">
          <thead>
            <tr>
              <th> # </th>
              <th> Student  </th>
              {questions?.map((question: any, index: any) => {
                return (
                  <th>{`Q${index + 1}`}</th>
                )
              })}
            </tr>
          </thead>
       
          <tbody>
            {
              Students?.map((student: any, index: any) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{student.name} - {student.id}</td>
                    {
                      questions?.map((question: any, index: any) => {
                        let findStudentQuestion: any = StudentsQuestions.find((sq: any) => sq.question_id == question.question_id && student.id == sq.student_id);

                        return (
                          <td  onClick={()=>{handleShowAnswer(findStudentQuestion)}} style={{ cursor :"pointer" , color:  findStudentQuestion?.score == 0 ||  findStudentQuestion?.score == findStudentQuestion?.total_mark ? "white" : "black", backgroundColor: findStudentQuestion?.score == findStudentQuestion?.total_mark ? "green" : findStudentQuestion?.score == 0 ? "red" : "yellow" }}>
                         
                            {
                              findStudentQuestion?.spenttime ? `${findStudentQuestion?.spenttime}  sec` : "NA"
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {/* // Question Analysis */}


      <div className="table-responsive" id="analysis-container">

        <table id="dataTable" className="table table-bordered ">
          <thead>
            <tr>
              <th># </th>
              <th>Question </th>
              <th>Learning outcome</th>
              <th>Cognitive level </th>
              <th>NO. correct answers </th>
              <th>NO. partialy correct answers </th>
              <th>NO. wrong answers </th>
              <th>Min duration (in seconds) </th>
              <th>Max duration (in seconds)</th>
              <th>Average duration (in seconds)</th>

            </tr>
          </thead>
          <tbody>
            {
              questions?.map((question: any, index: any) => {

                return (
                  <tr key={question.question_id}>
                    <td>{index + 1}</td>
                    <td className="text-truncate student_column" >{question.header?.replace(/<[^>]*>/g, '').substring(0, 100)}</td>
                    <td>{question.learning_outcome}</td>
                    <td>{question.cognitive}</td>
                    <td>{question.correct_answers_count}</td>
                    <td>{question.partial_answers_count}</td>
                    <td>{question.wrong_answers_count}</td>
                    <td>{question.min_time}</td>
                    <td>{question.max_time}</td>
                    <td>{question.avr_time}</td>


                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <Modal
               
               classNames={{
                   modal: "customModal_2"
               }}
               open={questionModal}
               onClose={()=>{  setQuestionModal(false)}}
              //  onClose={() => { props.handleModalClose(); props.setIsRetake(false); }}
               center
           >

              
          <QuestionRender q = {selectedQuestion} closeModal = {()=>{setQuestionModal(false)}} />

           </Modal>
      

    </div>
  )
}



export default AssessmentAnalysis;