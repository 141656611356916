import React, { useEffect, useRef, useState } from "react";
import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';
import { MapSecondsToTime, handleFileUpload2s3, makeId, requestWithAxios } from "../../helpers/func";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import axios from "axios";
import DocView from "./DocView";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import AWS from 'aws-sdk';
import CardTable from "./CardTable";
import DocumentModal from "./DocumentModal";
import LearningStandards from "./smallComponents/LearningStandards";



// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

const rowProps = ["name", "data", "type", "userName"];

const grading_dot_radius = "20px";


let docs: any = [{ uri: "" }];


const headers = {
  "Sec-Fetch-Mode": "cors",
  "Sec-Fetch-Site": "cross-site"
};

const imgTypes =
  [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "ico",
    "webp",
    "heic",
    "jfif",
    "heif"
  ]
const videoTypes = [
  "mp4",
  "ogg",
  "avi",
  "mov",
  "flv",
  "asf",
  "amv",
  "3gp",
  "mpeg"
]


function toBase64(file: any) {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({ file: reader.result, type: file.type, name: file.name });
    reader.onerror = (error) => reject(error);
  });
}

async function tobase64Handler(files: any) {
  const filePathsPromises: any = [];
  for (let i = 0; i < files.length; i++) {
    filePathsPromises.push(toBase64(files[i]));
  }

  const filePaths = await Promise.all(filePathsPromises);
  return filePaths;
}

// export const handleFullScreenClick = () => {
//   try {

//     const elem: any = document.documentElement;
//     if (elem.requestFullscreen) {
//       elem.requestFullscreen();
//     } else if (elem.mozRequestFullScreen) {
//       elem.mozRequestFullScreen();
//     } else if (elem.webkitRequestFullscreen) {
//       elem.webkitRequestFullscreen();
//     } else if (elem.msRequestFullscreen) {
//       elem.msRequestFullscreen();
//     }
//   } catch (error) {

//   }
// }

const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;
const REACT_APP_AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const REACT_APP_AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const S3_BUCKET = process.env.S3_BUCKET;

AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
})
const s3 = new AWS.S3(
  {
    params: { Bucket: S3_BUCKET },
    region: REACT_APP_AWS_REGION
  }
);

function truncateString(str: any, maxLength: any) {
  return str.length > maxLength ? str.slice(0, maxLength - 1) + '...' : str;
}


function customUrlEncode(url: any) {
  let encodedUrl = '';

  for (const char of url) {
    if (/[A-Za-z0-9\-_\.~:\/]/.test(char)) {
      // Characters that are allowed to remain unchanged
      encodedUrl += char;
    } else {
      // Encode other characters
      encodedUrl += encodeURIComponent(char);
    }
  }

  return encodedUrl;
}


const FileUploadEnhanced = (props: any) => {
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [loadingBarArr, setLoadingBarArr] = useState<any>([])
  const [showProgressArr, setShowProgressArr] = useState<any>([])
  const [userName, setUserName] = useState<any>("");
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [state, setState] = useState<any>({
    id: makeId(10),
    type: "FileUploadEnhanced",
    show_objectives: false,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "0",
    required: true,
    header:
      '',
    files: [],
    max_count: 0,
    calibration :"",
    max_number_of_files: 1,
    msg: "",
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null,
    studentexam_id: ""
  });
  let count = useRef<any>()
  useEffect(() => {

    if (props.quest != undefined) {
      let Question = { ...props.quest };
      Question.files = typeof Question.files == "string" ? JSON.parse(Question.files) : Question.files

      const len = Question.files?.length ?? 0;
      const loadingBarArrTmp = [];
      const showProgressArrTmp = [];
      for (let index = 0; index < len; index++) {
        showProgressArrTmp.push(false);
        loadingBarArrTmp.push("100%");
      }
      setLoadingBarArr(loadingBarArrTmp);
      setShowProgressArr(showProgressArrTmp);

      if (Question.mode == "examMode") {
        Swal.showLoading();
        axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
          setSpentTime(response.data.data);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)
        }).catch((err) => {
          setSpentTime(0);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)

        });
        Question.max_count = Question.files.lenght ?? 0;
        setState(Question)
        Swal.close();
      } else {
        if(Question.mode == "editingMode"){
           //Editing mode
           let cal_array: any = [];
           let calibrationObject = Question.calibration
           if (typeof Question.calibration == "string") {
             try {
               calibrationObject = JSON.parse(Question.calibration)
             } catch (error) {
               calibrationObject = Question.calibration
             }
           }
           if (Question.calibration !== undefined && Question.calibration !== null && Question.calibration !== "" && Question.rubricsmode != "Holistic") {
             let Analytical = calibrationObject?.Analytical ?? calibrationObject
             let splitted_calibration = Analytical?.split(",,");
             splitted_calibration.forEach((cal: any) => {
               // Regular expressions to extract numbers       
               var scoreRegex = /\b\d+\b/;
               var testRegex = /if the student's answer\s(.+)/;
               // Extracting the numbers
               var scoreMatch = cal.match(scoreRegex);
               var testMatch = cal.match(testRegex);
               // Converting matches to integers
               var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
               var text = testMatch ? testMatch[1] : null;
               if (total != null && text != null) {
   
                 let cal_obj = { total: total, text: text }
                 cal_array.push(cal_obj)
               }
             });
           } else {
             if (props.etype == "capstone" && Question.rubricsmode == "Analytical") {
               for (let index = 4; index > 0; index--) {
                 if (index == 1) continue;
                 cal_array.push({ total: index, text: "" });
               }
             }
           }
           if(cal_array.length ==0 ){
            for (let index = Question.total_mark; index > 0; index--) {
              cal_array.push({ total: index, text: "" });
  
            }
          }
           setState({ ...Question, calibrationArray: [...cal_array], calibration: calibrationObject })
          
           Swal.close()
        }else {

          setState(Question);
          Swal.close()
        }
      }
    } else {
      let cal_array: any = [];
            let total_mark = 0
            if (state.calibration !== undefined && state.calibration !== null && state.calibration !== "") {
              let splitted_calibration = state.calibration?.Analytical?.split(",,");
              splitted_calibration.forEach((cal: any) => {
                // Regular expressions to extract numbers       
                var scoreRegex = /\b\d+\b/;
                var testRegex = /if the student's answer\s(.+)/;
                // Extracting the numbers
                var scoreMatch = cal.match(scoreRegex);
                var testMatch = cal.match(testRegex);
                // Converting matches to integers
                var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
                var text = testMatch ? testMatch[1] : null;
                if (total != null && text != null) {
      
                  let cal_obj = { total: total, text: text }
                  cal_array.push(cal_obj)
                }
              });
            } else {
              if (props.etype == "capstone") {
                total_mark = 4
                for (let index = 4; index > 0; index--) {
                  if (index == 1) continue;
                  cal_array.push({ total: index, text: "" });
      
                }
              }
      
            }
    
      setState((prev: any) => ({ ...prev, mode: "editingMode" , all_targets : props.course_standards_targets }))

      Swal.close()
    }
  }, []);
  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        // props.onSaveQuestion({ ...state , spenttime : count?.current?.value })
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count?.current?.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId]);


  let colors = [
    "blue",
    "green",
    "yellow"
  ]


  // state = obj;
  const onSaveQuestion = (updatedState: any) => {
    let require = handleCount(state.header);
    if (require) {
      Swal.fire({
        icon: 'error',
        text: ' Question Header is required',
      }).then(() => {

      })
    } else if ((state.total_mark.length == 0) || (state.total_mark == ".")) {
      Swal.fire({
        icon: 'error',
        text: 'Total mark is required ',
      }).then(() => {

      })
    } else if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
      Swal.showLoading();
      axios.post(`${BASE_URL}/student/saveq.php`, updatedState).then((res: any) => {
        Swal.close();
      })
      props.onSaveQuestion(updatedState)
    } else {
      if (updatedState.mode == "editingMode") {

        let calibration = "";
        if (updatedState.calibrationArray?.length > 0 && updatedState.calibration?.Holistic) {
          updatedState.rubricsmode = "Both"
          for (let index = updatedState.total_mark; index > 0; index--) {

            if (props.etype == "capstone" && index == 1) continue;

            let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
            calibration_text = calibration_text.replace(/,,/g, ',')
            calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
          }
          if (props.etype == "capstone") {

            calibration += " else set score to 1";
          } else {
            calibration += " else set score to 0";
          }
          updatedState.calibration.Analytical = calibration;

        } else if (updatedState.calibrationArray?.length == 0 && updatedState.calibration?.Holistic) {
          updatedState.rubricsmode = "Holistic"
          updatedState.calibration.Analytical = calibration;


        } else {
          updatedState.rubricsmode = "Analytical"
          for (let index = updatedState.total_mark; index > 0; index--) {

            if (props.etype == "capstone" && index == 1) continue;

            let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
            calibration_text = calibration_text.replace(/,,/g, ',')
            calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
          }
          if (props.etype == "capstone") {

            calibration += " else set score to 1";
          } else {
            calibration += " else set score to 0";
          }
          let calibrationObj = {
            Analytical: calibration,
            Holistic: ""
          }
          updatedState.calibration = calibrationObj;

        }

      }

      props.onSaveQuestion(updatedState)
    }
  }


  const deleteFileHandler = (id: any) => {

    let fileInput: any = document.getElementById(`addFile${state.id}`) as HTMLInputElement;
    if (fileInput?.value) {

      fileInput.value = "";
    }
    let files = [...state.files.filter((file: any) => file.id !== id)];
    let updatedState = { ...state, files }
    setState((prev: any) => ({ ...prev, files: files, max_count: files.length - 1 }));
    onSaveQuestion(updatedState)
  };


  const save2php = (updatedState: any) => {
    setState(updatedState);

    if (updatedState.mode === "examMode") {

      let tempState = { ...updatedState }
      const tmp = [...tempState?.files]
        ?.filter((row: any) => (!row.hasFailedFlag)).map((row: any) => {
          const obj: any = {}
          rowProps.forEach((rowProp: any) => {
            if (row.hasOwnProperty(rowProp)) { obj[rowProp] = row[rowProp]; }
          });
          return obj;
        })
      tempState.files = [...tmp];
      requestWithAxios(
        BASE_URL + "student/saveq.php",
        tempState
      );

      onSaveQuestion(updatedState)

    }

  };

  useEffect(() => {

    let user_name: any = "";
    let token: any = null;
    if ("examMode" == state?.mode) {
      token = Cookies.get("tokentest");
    } else {
      token = Cookies.get("quiztoken");
    }
    if (token) {
      const decodedToken: any = jwt_decode(token);
      user_name = decodedToken?.data?.userName;
    }
    if (user_name) {
      setUserName(user_name)
    }
    return () => {

    }
  }, [])

  const attachmentTableRowFactory = (name: any, type: any, data: any , id="") => {
    const newRow: any = {
      id: id == "" ? makeId(10) : id,
      data,
      name,
      type,
      userName,
      hasFailedFlag: false,
    };

    let updatedState = { ...state }
    let foundIndex = updatedState.files?.findIndex((file:any)=> file.id == id);
     if(foundIndex != -1) {
           updatedState.files[foundIndex] = newRow
     }else {

       updatedState?.files?.push(newRow);
     }
    setState(updatedState);
  }



  const handleFileUpload2s3 = async (file: any, file_index: any , id ="") => {

    // const file_index = state?.files?.length ?? 0;
    // setLoadingBar("0");
    loadingBarArr.push("0");
    setLoadingBarArr((x: any) => [...x])
    // setShowProgress(true)
    showProgressArr.push(true);
    setShowProgressArr((x: any) => [...x])
    //  e.preventDefault();
    // Swal.showLoading();


    if (!file) {
      console.error('No file selected for upload');
      return;
    }
    // Replace with your bucket name
    const bucketName = 'ivyis';
    // Unique object key for the file
    const rootPath = "Assessments";

    const name = file.name;
    // Extract the file extension
    const fileExtension = name?.split(".")?.pop();

    // Remove the original file extension
    const baseName = name?.replace(`.${fileExtension}`, "");

    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the timestamp
    const year = currentDate.getFullYear(); // e.g., 2024
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // e.g., 04
    const day = currentDate.getDate().toString().padStart(2, "0"); // e.g., 22
    const hours = currentDate.getHours().toString().padStart(2, "0"); // e.g., 06
    const minutes = currentDate.getMinutes().toString().padStart(2, "0"); // e.g., 30
    const seconds = currentDate.getSeconds().toString().padStart(2, "0"); // e.g., 45

    // Combine the components into a timestamp string
    const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}`;



    // Append the timestamp and the file extension
    const modifiedString = `${truncateString(baseName, 50)}_${timestamp}.${fileExtension}`;


    const objectKey = `${rootPath}/${state?.id}/${modifiedString}`;
    const encodedUrl = customUrlEncode(objectKey);
    const driveid = `https://${bucketName}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/${encodedUrl}`;

    const contentType = file.type;
  
    attachmentTableRowFactory(name, contentType, driveid , id)


    const param = {
      ACL: 'public-read',
      Bucket: bucketName,
      Key: objectKey,
      ContentType: contentType,
      Body: file
    };


    try {

      return new Promise((resolve, reject) => {
        // Simulating asynchronous upload process
        var upload = s3.putObject(param).on("httpUploadProgress", (evt: any) => {
          const loadingBarTmp = `${((evt.loaded * 100) / evt.total)}` + "%";
          // setLoadingBar(loadingBarTmp);
          loadingBarArr[file_index] = loadingBarTmp;
          setLoadingBarArr((x: any) => [...loadingBarArr])

      
        }).promise();

        upload.then((d: any) => {

          resolve(driveid);

          // Swal.fire("Uploaded Successfully", "", "success")


          // setShowProgress(false)
          showProgressArr[file_index] = false;
          setShowProgressArr((x: any) => [...showProgressArr])
        }).catch((err) => {
          reject(err);
        })
      });

    } catch (error) {
      if ((error as any).code === 'NotFound') {
        console.error('Bucket does not exist or is not accessible');
        return;
      }
      // throw error;
    }
  }


  const catchFileUpload2s3 = async (file: any,id="") => {

    if (!file) {
      return
    }

    const file_index = state?.files?.length ?? 0;
    handleFileUpload2s3(file, file_index , id)
      .then(() => {
        save2php(state);
        onSaveQuestion(state);
      })
      .catch((err: any) => {
        let tempState = { ...state }
        const tmpFiles = [...tempState?.files]
      
        if (tmpFiles) {
         
         
          let obj = tmpFiles.find((file:any)=> file.id == id );

           if(!obj) obj = tmpFiles[file_index]
          if (obj) {
            if (obj?.name === file?.name) {
              showProgressArr[file_index] = false;
              setShowProgressArr((x: any) => [...showProgressArr])
              obj.hasFailedFlag = true;
              obj.file = file;
              tempState.files = [...tmpFiles];
            }
          }
      
          setState(tempState);


        }
        Swal.fire({
          icon: 'error',
          text: 'Something wrong, Please check your internet and try again ',
        })

        return;
        // setShowProgress(false)
        // let tempState = { ...state }
        // const tmp = [...tempState?.files]
        // if (tmp) {
        //   const len = tmp.length;
        //   if (len) {
        //     const obj = tmp[len - 1]
        //     if (obj?.name === file?.name) {
        //       obj.hasFailedFlag = true;
        //       obj.file = file;
        //       tempState.files = [...tmp];
        //     }
        //   }
        // }
      });

  }

  const reuploadFileUpload2s3 = async (in_file: any, file_index: any) => {

    if (!in_file) {
      return
    }
    // let file_index = 0;
    const file = { ...in_file };
    let tempState = { ...state }
   

    // showProgressArr
    //   ?.splice(file_index, 1);
    setShowProgressArr(showProgressArr.filter((x: any, i: number) => (i != file_index)))
    // loadingBarArr
    //   ?.splice(file_index, 1);
    setLoadingBarArr(loadingBarArr.filter((x: any, i: number) => (i != file_index)))
    const tmp = [...tempState?.files]?.filter((row: any, i: any) => (i != file_index))
    
    tempState.files = tmp;
   
 
    setState(tempState);
   

    catchFileUpload2s3(file.file , file.id)

  }


  const openAddFileInputHandler = () => {
    const fileInput = document.getElementById(`addFile${state.id}`) as HTMLInputElement;
    fileInput.click();
  };
  const addFileHandler = async (e: any) => {

    let filesLength = 0;
    if (Array.isArray(state.files)) {
      filesLength = state.files.length
    }
    let total_length = e.target.files.length + filesLength;
    if (total_length > state.max_number_of_files) {
      const msg = `Your limit is ${state.max_number_of_files} files only`;
      e.target.value = null; // discard selected file

      setState((prev: any) => ({ ...prev, msg: msg }));
      return false;
    }
    const fileInput = e?.target;
    const files_arr = [...fileInput?.files];
    fileInput.value = "";
    files_arr?.forEach((file: any) => {
      try {
        catchFileUpload2s3(file)
      } catch (error) { }
    });
    // const filess: any = []
    // for (let index = 0; index < e.target.files.length; index++) {
    //   const file = e.target.files[index];
  
    //   handleFileUpload2s3(file, state.id).then((res: any) => {
    //     filess.push({
    //       id: makeId(10),
    //       data: res,
    //       type: file.type.split("/")[0],
    //       type_1: file.type.split("/")[1] ?? "",
    //       name: file.name,

    //     });


    //     let newFiles: any = filess;
    //     let files: any = [];
    //     if (Array.isArray(state.files)) {

    //       files = state.files.concat(newFiles);
    //     } else {
    //       files = [...newFiles];
    //     }
    //     setState(
    //       (prev: any) => ({
    //         ...prev, files: files,
    //         max_count: state.max_count - filess.length,
    //         msg: "",
    //       })
    //     );

    //     let updatedState = { ...state, files }
    //     if (state.mode == "examMode")
    //       onSaveQuestion(updatedState)
    //   }).catch((err) => {
    //     Swal.fire({
    //       icon: "error",
    //       text: "File was not uploaded, please check your internet connection"
    //     })
    //   })

    //   // handleFullScreenClick()

    // }

  };

  const handleDocumentdriveid = (file: any) => {
    setSelectedFile(file)
    setOpenDocModal(true);
  };



  const onHandleCount = () => {

    let require = handleCount(state.header);
    if (require === false) {
      setState((prev: any) => ({ ...prev, required: false }));
    } else {
      setState((prev: any) => ({ ...prev, required: true }));

    }
  }

  // let files: any = [];


  // if (Array.isArray(state.files)) {
  
  //   files = state.files?.map((file: any, i: number) => {


  //     return <div className="col-12 rounded " key={file.id}>
  //       {(
  //         () => {

  //           if ((!file) || (!file?.data)) {
  //             return <div key={file?.id} > File type is not valid</div>;
  //           }
  //           const extension = file.name.split(".").pop();

  //           const src = file.id ? file.data : file.data.includes("https://") ? file.data : `https://drive.google.com/uc?id=${file.data}&export=download`;
  //           if ((file?.type?.toLowerCase() === "image") || (imgTypes?.includes(file?.type_1?.toLowerCase())) || (imgTypes?.includes(extension?.toLowerCase()))) {
  //             return (
  //               <img
  //                 key={file?.id}
  //                 width="100%" height="500px"
  //                 src={src}
  //                 alt="..."
  //               ></img>
  //             )
  //           }


  //           if (
  //             ((file?.type?.toLowerCase() === "pdf") || (file?.type_1?.toLowerCase() === "pdf") || (extension?.toLowerCase() === "pdf"))
  //             ||
  //             ((file?.type?.toLowerCase() === "txt") || (file?.type_1?.toLowerCase() === "txt") || (extension?.toLowerCase() === "txt"))
  //             ||
  //             ((file?.type?.toLowerCase() === "plain") || (file?.type_1?.toLowerCase() === "plain") || (extension?.toLowerCase() === "plain"))
  //             ||
  //             ((file?.type?.toLowerCase() === "html") || (file?.type_1?.toLowerCase() === "html") || (extension?.toLowerCase() === "html"))
  //             ||
  //             ((file?.type === "video") || (videoTypes.includes(file?.type_1?.toLowerCase())) || (videoTypes.includes(extension?.toLowerCase())))
  //           ) {
  //             return (
  //               <iframe src={src} width="100%" height="500px">

  //               </iframe>
  //             );
  //           }

  //           if (src?.includes("amazonaws.com/")) {
  //             // return <hr />;
  //             if (docs.length < (1 + i)) {
  //               docs.push({ uri: src })
  //             } else {
  //               docs[i].uri = src;
  //             }
  //             try {

  //               return <DocViewer key={file?.id} documents={docs} requestHeaders={headers} prefetchMethod="GET" activeDocument={docs[i]} pluginRenderers={DocViewerRenderers} />

  //             } catch (error) {
  //               return null;
  //             }
  //             // return <div
  //             //   style={{
  //             //     width: "100%",
  //             //     minHeight: "700px",
  //             //   }}
  //             // >
  //             //   <DocViewer 
  //             //   key={file?.id} 
  //             //   documents={docs} 
  //             //   requestHeaders={headers} 
  //             //   prefetchMethod="GET" 
  //             //   activeDocument={docs[i]} pluginRenderers={DocViewerRenderers}
  //             //    />
  //             // </div>
  //           }

  //           return <iframe src={src} width="100%" height="500px">

  //           </iframe>

  //         }
  //       )()}
  //       {state.mode === "examMode" ? (
  //         <i
  //           className="fas fa-minus-circle deleteFile"
  //           onClick={() => deleteFileHandler(file.id)}
  //         ></i>
  //       ) : null}

  //     </div>

  //     // return (
  //     //   <div className="col-12 rounded " key={file.id}>
  //     //     {(
  //     //       <iframe src={src} width="100%" height="500px">

  //     //       </iframe>
  //     //     )}
  //     //     {state.mode === "examMode" ? (
  //     //       <i
  //     //         className="fas fa-minus-circle deleteFile"
  //     //         onClick={() => deleteFileHandler(file.id)}
  //     //       ></i>
  //     //     ) : null}

  //     //   </div>
  //     // );

  //   });
  // }

  const handleCalibration = (e: any, total: any) => {
    let calibrationArray = [...state.calibrationArray]

    let calibration_object = { total: total, text: e.target.value }
    let findIndex = calibrationArray.findIndex((cal: any) => cal.total == total);

    if (findIndex > -1) {
      calibrationArray[findIndex] = calibration_object
    } else {
      calibrationArray.push(calibration_object)
    }

    setState((prev: any) => ({ ...prev, calibrationArray: [...calibrationArray] }))

  }
  
  return (
    // <div className="mx-5">
    <div className="">


      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 mx-auto bg-question row align-items-center rounded p-3 text-white">
          <div className={`col-12 row ${state.mode == "examMode" ? "" : "justify-content-between"}`}>

            <h3>
              {state.mode === "editingMode"
                ? "Question Setup"
                : ""}
            </h3>

            <div className="d-flex flex-column">
              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow ">
                  Total mark :
                </span>
                {state.mode === "editingMode" ||
                  state.mode === "answeringMode" ? (
                  <input
                    type="number"
                    min={0}
                    className="col form-control"
                    name=""
                    id=""
                    value={state.total_mark}
                    onChange={(e) => {
                      let total_mark = e.target.value;
                      if ((typeof +total_mark == "number") || total_mark == "" || total_mark == ".") {
                        

                        let temp_calibration: any = [];
                        for (let index = +total_mark; index > 0; index--) {
                          temp_calibration.push({ total: index, text: "" });

                        }

                        setState((prev: any) => ({ ...prev, calibrationArray: [...temp_calibration], total_mark: +total_mark }))
                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <span className="text-shadow">{state.total_mark}</span>
                ) : null}
                {state.mode === "gradingModeTeacher" ? (
                  <>
                    <span className="col-auto border-left ml-2">
                      Score :
                    </span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={state.score}
                      onChange={(e) => {
                        let score = e.target.value
                        let updatedState = { ...state, score }
                        onSaveQuestion(updatedState)

                        setState((prev: any) => ({ ...prev, score: score }));
                      }
                      }
                    />
                  </>
                ) : state.mode === "gradingModeStudent" ? (
                  <span className="border-left ml-2 pl-2 text-shadow">
                    Score :  {state.score}
                  </span>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  File Upload
                </div>
              </div>

          }







          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })); onHandleCount(); }}
                  onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a })); onHandleCount(); }}
                  defaultValue={state.header}
                  height={"10px"}
                ></RichBox>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "answeringMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div
                className="bg-white w-100 text-dark rounded align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: state.header }}
              ></div>
            ) : (
              <div></div>
            )}
          </div>

          {state.mode === "editingMode" ? (
            <div className="col-12 row align-items-center my-3 mx-auto">
              <label htmlFor="max" className="col-auto">
                Max Files
              </label>
              <select
                className="col-sm-1 form-control"
                name=""
                id=""
                value={state.max_number_of_files}
                onChange={(e: any) => {
                  let max_files = +e.target.value
                  setState((prev: any) => ({
                    ...prev,
                    max_count: max_files - state.files.length,
                    max_number_of_files: max_files,
                  }))
                }
                }
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
          ) : (
            <div className="col-12 row align-items-center my-3">
              <label htmlFor="max" className="col-auto">
                Max Files: {state.max_number_of_files}
              </label>
            </div>
          )}
          <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />

          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })); }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })); }}
            />

          ) : null}
              <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
            all_targets={state.all_targets?.length > 0? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets = {(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}

          {state.mode === "examMode" && state.max_count < state.max_number_of_files ? (
            <div className="col-12">
              <button
                className="btn bg-info text-white"
                onClick={openAddFileInputHandler}
              >
                upload
              </button>
              <input
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                id={`addFile${state.id}`}
                multiple={true}
                hidden={true}
                type="file"
                onChange={addFileHandler}
              />
            </div>
          ) : null}

          {state.mode === "examMode" ||
            state.mode === "gradingModeTeacher" ||
            state.mode === "gradingModeStudent" ? (
            <div className="col-12 row p-3 align-content-start align-items-center mx-auto justify-content-start">
              {state.msg && (
                <div className="col-12">
                  <h3>{state.msg}</h3>
                </div>
              )}
              {

                // attachment table
                (
                  () => {

                    const headerArr: any = [
                      "Name",
                    ];

                    const file_flag = ("examMode" === state.mode) || ("gradingModeTeacher" === state.mode)

                    if (file_flag) {
                      // Action is only in editingMode so this col might not be rendered
                      headerArr.push("Action");
                    }
                    const attachmentTable = state?.files;
                    const rowArr = attachmentTable?.map((row: any, rowIndex: any) => {

                      let hasFailedFlag = false;
                      const rowProp2 = "hasFailedFlag";
                      if (row.hasOwnProperty(rowProp2)) { hasFailedFlag = row[rowProp2]; }

                      // used iife to reduce typos
                      const arr: any = [
                        () => {
                          if
                            (showProgressArr[rowIndex])
                          // (showProgress && ((attachmentTable.length - 1) === rowIndex)) 
                          {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  height: "10px",
                                  background: "white",
                                  borderRadius: "10px",
                                  border: "1px solid green",
                                }}
                              >

                                <div
                                  style={{
                                    width: loadingBarArr[rowIndex],
                                    height: "10px",
                                    borderRadius: "10px",
                                    background: "#0c50a7",
                                  }}
                                >

                                </div>
                              </div>
                            );
                          }
                          // the name can be changed in editingMode
                          let name = "";
                          const rowProp = "name";
                        
                          if (row.hasOwnProperty(rowProp)) { name = row[rowProp] }else{name =row["data"].split("/").pop();}

                          return <div
                            style={{
                              cursor: hasFailedFlag ? "not-allowed" : "pointer",
                              color: hasFailedFlag ? "black" : "blue",
                              textDecoration: hasFailedFlag ? "none" : "underline",
                            }}

                            onClick={() => {
                              if (!hasFailedFlag) {
                                handleDocumentdriveid(row)
                              }
                            }}
                          >
                            <span  >{name}</span>
                            {
                              (hasFailedFlag) ?
                                <>
                                  <br />
                                  <span
                                    style={{
                                      color: "red",
                                    }}
                                  >could not be uploaded. Please try again.</span>
                                </>
                                :
                                null
                            }
                          </div>
                        },
                      ];

                      if (file_flag) {
                        const delete_disabled = (userName !== row?.userName);
                        // Action is only in editingMode so this col might not be rendered
                        arr.push(() => {
                          return <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            {
                              (hasFailedFlag) ?
                                <button
                                  // disabled={delete_disabled}
                                  className='btn btn-success'
                                  onClick={() => {
                                   
                                    reuploadFileUpload2s3(row, rowIndex);
                                  }}
                                >
                                  {/* <i className="fa-solid fa-rotate-right"></i> */}
                                  <i className="fas fa-rotate-right"></i>
                                </button>
                                :
                                null
                            }
                            <button
                              disabled={delete_disabled}
                              className='btn btn-danger'
                              onClick={() => {
                                Swal.fire({
                                  icon: 'warning',
                                  text: 'Are You Sure?',
                                }).then((v: any) => {
                                  if (v?.isConfirmed) {
                                    const updatedState = { ...state };
                                    updatedState.files = attachmentTable?.filter((x_row: any, index: any) => rowIndex != index);

                                    setShowProgressArr(showProgressArr.filter((x: any, i: number) => (i != rowIndex)))

                                    setLoadingBarArr(loadingBarArr.filter((x: any, i: number) => (i != rowIndex)))

                                    save2php(updatedState);
                                  }
                                })
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>


                        });
                      }
                      return { serial_hash: row.data, arr }

                    });
                    let addBtn = null;

                    return <CardTable
                      addBtn={addBtn}
                      headerArr={headerArr}
                      rowArr={rowArr}
                    />
                  }
                )()}
            </div>
          ) : null}

          <div className="col-12 row">
            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div className="col-12 row align-items-start align-content-start p-2 mx-auto">
                <label className="align-self-start">Feedback</label>
                <textarea
                  className="form-control align-self-stretch "
                  name=""
                  id=""
                  value={state.feedback}
                  rows={5}
                  disabled={
                    state.mode === "gradingModeStudent" ? true : false
                  }
                  onChange={(e) => {
                    let feedback = e.target.value;
                    let updatedState = { ...state, feedback }
                    onSaveQuestion(updatedState)
                    setState((prev: any) => ({ ...prev, feedback: feedback }));
                  }
                  }
                ></textarea>
              </div>
            ) : null}
          </div>



       
{state.mode === "editingMode" ? (
            <div className="row col-12">

              <div className=" col-md-6 my-2"> Analytical Rubrics :
                <div className="  row  col-12 ">
                  {
                    state.calibrationArray?.sort((a: any, b: any) => b.total - a.total).map((cal: any, index: any) => {
                      let total = cal.total
                      if (total == 1 && props.etype == "capstone") {
                        return (
                          <div></div>)
                      }
                      return (
                        <div className="col-6 my-3" key={total} >
                          <label>The score will be {total}
                            {
                              props.etype == "capstone" ? (<span >  ({colors[index]})</span>) : ``
                            } if :
                          </label>
                          <textarea className="form-control" onChange={(e: any) => { handleCalibration(e, total) }}
                            value={state.calibrationArray?.find((cal: any) => cal.total == total)?.text ?? ""}
                          ></textarea>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className=" col-md-6 my-2"> Holistic Rubrics :
                <div className="  row  col-12 ">
                  {
                    <div className="col-12 my-3"  >
                      <label>
                      </label>
                      <textarea className="form-control" onChange={(e: any) => {
                        let value = e.target.value
                        let temp_calibration = state.calibration;
                        console.log(temp_calibration)
                        temp_calibration = {
                          Analytical: ""

                        }
                        temp_calibration["Holistic"] = value;
                        console.log(temp_calibration)
                        setState((prev: any) => ({ ...prev, calibration: temp_calibration }))
                      }}
                        value={state.calibration?.Holistic}
                      ></textarea>
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : ""}



          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg rounded col-3 mt-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>

      {openDocModal ?
        <DocumentModal open={openDocModal} setOpen={setOpenDocModal} file={selectedFile} section_id={""} >
          {
            (
              () => {
                if (selectedFile.data === undefined || selectedFile.data === null) {
                  return <div> File type is not valid</div>
                }

                const type = (selectedFile?.type?.split("/")[1] ?? selectedFile?.type)?.toLowerCase();
                    
                if (type === "pdf") {
                  return <iframe src={selectedFile.data} width="100%" height="100%">

                  </iframe>
                }

                if (type === "txt" || type == "plain" || type === "html" || videoTypes.includes(type)) {
                  return <iframe
                    src={selectedFile.data}
                    width="100%"
                    style={{ height: "85vh" }}
                    title="title"

                  ></iframe>

                }

                if (imgTypes.includes(type) || type == "image") {
                  return <div style={{ height: "85vh", width: "100%" }}>
                    <img style={{ width: "100%" }} src={selectedFile.data} />
                  </div>

                }

                if (selectedFile.data.includes("amazonaws.com/")) {
                  docs[0].uri = selectedFile.data;
                  return <DocViewer documents={docs} requestHeaders={headers} prefetchMethod="GET" activeDocument={docs[0]} pluginRenderers={DocViewerRenderers} />

                }

                if (selectedFile.data.includes("https://") === false) {
                  return <iframe

                    src={`https://drive.google.com/file/d/${selectedFile.data}/preview`}
                    width="100%"
                    style={{ height: "85vh" }}
                    title="title"
                  ></iframe>

                }

                return <iframe
                  src={selectedFile.data}
                  width="100%"
                  style={{ height: "85vh" }}
                  title="title"

                ></iframe>
              }
            )()
          }

        </DocumentModal> : ""
      }


    </div>
  );
  // }

}
export default FileUploadEnhanced