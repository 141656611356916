import React, { useEffect, useRef, useState } from "react";

import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/wave.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';
import { MapSecondsToTime, handleFileUpload2s3, makeId, requestWithAxios, base64ToBlob } from "../../helpers/func";
import { ReactMediaRecorder } from "react-media-recorder";


import AudioPreview from "./smallComponents/AudioPreview";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import axios from "axios";
import LearningStandards from "./smallComponents/LearningStandards";


// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent


const AudioComponent = (props: any) => {
  const [state, setState] = useState<any>({
    id: makeId(10),
    type: "AudioComponent",
    isRecording: false,
    show_objectives: false,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: "",
    score: "0",
    header: "",
    calibration: "",
    all_targets: [],
    media_blob_url: "",
    show_recording_opt: true,
    headertxt: '',
    required: true,
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null,
    spenttime: 0
  })
  let count = useRef<any>()
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  const onSaveQuestion = (updatedState: any) => {
    let require = handleCount(state.header);
    if (require) {
      Swal.fire({
        icon: 'error',
        text: ' Question Header is required',
      }).then(() => {

      })
    } else if ((state.total_mark.length == 0) || (state.total_mark == ".")) {
      Swal.fire({
        icon: 'error',
        text: ' Total mark is required',
      }).then(() => {

      })
    } else {
      if (updatedState.mode == "editingMode") {

        let calibration = "";
        if (updatedState.calibrationArray?.length > 0 && updatedState.calibration?.Holistic) {
          updatedState.rubricsmode = "Both"
          for (let index = updatedState.total_mark; index > 0; index--) {

            if (props.etype == "capstone" && index == 1) continue;

            let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
            calibration_text = calibration_text.replace(/,,/g, ',')
            calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
          }
          if (props.etype == "capstone") {

            calibration += " else set score to 1";
          } else {
            calibration += " else set score to 0";
          }
          updatedState.calibration.Analytical = calibration;

        } else if (updatedState.calibrationArray?.length == 0 && updatedState.calibration?.Holistic) {
          updatedState.rubricsmode = "Holistic"
          updatedState.calibration.Analytical = calibration;


        } else {
          updatedState.rubricsmode = "Analytical"
          for (let index = updatedState.total_mark; index > 0; index--) {

            if (props.etype == "capstone" && index == 1) continue;

            let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
            calibration_text = calibration_text.replace(/,,/g, ',')
            calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
          }
          if (props.etype == "capstone") {

            calibration += " else set score to 1";
          } else {
            calibration += " else set score to 0";
          }
          let calibrationObj = {
            Analytical: calibration,
            Holistic: ""
          }
          updatedState.calibration = calibrationObj;

        }

      }
      props.onSaveQuestion(updatedState)
    }
  }
  useEffect(() => {
    Swal.showLoading();
    if (props.quest != undefined) {
      let Question = { ...props.quest };

      if (Question.mode != "editingMode") {
        if (Question.mode == "examMode") {
          Swal.showLoading();
          axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
            setSpentTime(response.data.data);
            const intervalId = setInterval(() => {
              setSpentTime((prev: any) => ++prev);
              // props.onSaveQuestion({ ...state })
            }, 1000);
            setIntervalId(intervalId)
          }).catch((err) => {
            setSpentTime(0);
            const intervalId = setInterval(() => {
              setSpentTime((prev: any) => ++prev);
              // props.onSaveQuestion({ ...state })
            }, 1000);
            setIntervalId(intervalId)

          });
        }

        $("#preloader").hide();

        Swal.showLoading();
        if (Question.media_blob_url?.includes("https") && Question.media_blob_url?.includes(".txt")) {
          Swal.showLoading();

          fetch(Question.media_blob_url).then((res: any) => {
            Swal.showLoading();
            res.text().then((data: any) => {

              Question.media_blob_url = data
              setState(Question)
              Swal.close()
            })

          }).catch((err) => {
            setState(Question)
            Swal.fire({
              icon: "error",
              text: "Audio will not work if you have problem with internet connection"
            });

          })
        } else {
          setState(Question)
          Swal.close()
        }
      } else {
        //Editing mode
        let cal_array: any = [];
        let calibrationObject = Question.calibration
        if (typeof Question.calibration == "string") {
          try {
            calibrationObject = JSON.parse(Question.calibration)
          } catch (error) {
            calibrationObject = Question.calibration
          }
        }
        if (Question.calibration !== undefined && Question.calibration !== null && Question.calibration !== "" && Question.rubricsmode != "Holistic") {
          let Analytical = calibrationObject?.Analytical ?? calibrationObject
          let splitted_calibration = Analytical?.split(",,");
          splitted_calibration.forEach((cal: any) => {
            // Regular expressions to extract numbers       
            var scoreRegex = /\b\d+\b/;
            var testRegex = /if the student's answer\s(.+)/;
            // Extracting the numbers
            var scoreMatch = cal.match(scoreRegex);
            var testMatch = cal.match(testRegex);
            // Converting matches to integers
            var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
            var text = testMatch ? testMatch[1] : null;
            if (total != null && text != null) {

              let cal_obj = { total: total, text: text }
              cal_array.push(cal_obj)
            }
          });
        } else {
          if (props.etype == "capstone" && Question.rubricsmode == "Analytical") {
            for (let index = 4; index > 0; index--) {
              if (index == 1) continue;
              cal_array.push({ total: index, text: "" });
            }
          }
        }
        if(cal_array.length ==0 ){
          for (let index = Question.total_mark; index > 0; index--) {
            cal_array.push({ total: index, text: "" });

          }
        }
        setState({ ...Question, calibrationArray: [...cal_array], calibration: calibrationObject })

        Swal.close()
      }
    } else {
      let cal_array: any = [];
      let total_mark = 0
      if (state.calibration !== undefined && state.calibration !== null && state.calibration !== "") {
        let splitted_calibration = state.calibration?.Analytical?.split(",,");
        splitted_calibration.forEach((cal: any) => {
          // Regular expressions to extract numbers       
          var scoreRegex = /\b\d+\b/;
          var testRegex = /if the student's answer\s(.+)/;
          // Extracting the numbers
          var scoreMatch = cal.match(scoreRegex);
          var testMatch = cal.match(testRegex);
          // Converting matches to integers
          var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
          var text = testMatch ? testMatch[1] : null;
          if (total != null && text != null) {

            let cal_obj = { total: total, text: text }
            cal_array.push(cal_obj)
          }
        });
      } else {
        if (props.etype == "capstone") {
          total_mark = 4
          for (let index = 4; index > 0; index--) {
            if (index == 1) continue;
            cal_array.push({ total: index, text: "" });

          }
        }

      }
      setState((prev: any) => ({ ...prev, calibrationArray: [...cal_array], total_mark: total_mark, mode: "editingMode", all_targets: props.course_standards_targets }))



      Swal.close()
    }

  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId])

  const onHandleCount = () => {

    let require = handleCount(state.header);
    if (require === false) {
      setState((prev: any) => ({ ...prev, required: false }))
    } else {
      setState((prev: any) => ({ ...prev, required: true }))
    }
  }

  let colors = [
    "blue",
    "green",
    "yellow"
  ]


  const onSaveVideo = async (media_blob_url: any) => {
    // axios.get(media_blob_url).then((r) => r.blob())
    let blob = await fetch(media_blob_url)
      .then((r) => r.blob())
      .then((blobFile) => {
        let file = new File([blobFile], "fileNameGoesHere", {
          type: "audio/wav",
        });
        var reader: any = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          console.log(typeof reader.result)
          if (reader.result.split(":")[1]?.split("/")[0] === "audio") {
            setState((prev: any) => ({
              ...prev, media_blob_url: reader.result,
              showRecordingOpt: false
            }))


            let media_blob_url = reader.result;
            let updatedState = { ...state, media_blob_url, isRecording: null }
            if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {

              console.log(media_blob_url)
              const audioBlob = base64ToBlob(media_blob_url, 'audio/wav');
              var formData = new FormData();
              formData.append('file', audioBlob, "audiofile.wav");

              handleFileUpload2s3(formData.get("file"), state.id).then((res: any) => {
                console.log(res);
                updatedState.media_blob_url = res;
                onSaveQuestion(updatedState)
                Swal.showLoading();
                axios.post(
                  BASE_URL + "student/saveq.php",
                  updatedState
                ).then(response => {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: 'Question Saved Successfully',
                  }).then(() => {

                  })
                })
              }).catch((err) => {
                console.log(err);
                Swal.fire({
                  icon: 'error',
                  text: 'Audio was not uploaded , Please check your internet and try again ',
                })
              })


            }
          }

        };
      });
  };


  const handleCalibration = (e: any, total: any) => {
    let calibrationArray = [...state.calibrationArray]

    let calibration_object = { total: total, text: e.target.value }
    let findIndex = calibrationArray.findIndex((cal: any) => cal.total == total);

    if (findIndex > -1) {
      calibrationArray[findIndex] = calibration_object
    } else {
      calibrationArray.push(calibration_object)
    }

    setState((prev: any) => ({ ...prev, calibrationArray: [...calibrationArray] }))

  }


  return (
    // <div className="mx-5">
    <div className="">

      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div >
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 row justify-content-between">
            <h3 className="text-shadow">Question Setup</h3>
            {/*<button
                className="btn bg-info text-white"
                onClick={stateToJsonGradingMode}
              >
                show
              </button>*/}
            <div className="d-flex flex-column">
              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow ">
                  Total mark :
                </span>
                {state.mode === "editingMode" ? (
                  <input
                    type="number"
                    min={0}
                    className="col form-control"
                    name=""
                    value={state.total_mark}
                    id=""
                    onChange={(e) => {
                      if ((typeof +e.target.value == "number") || e.target.value == "" || e.target.value == ".") {
                        let total_mark = +e.target.value

                        let temp_calibration: any = [];
                        for (let index = total_mark; index > 0; index--) {
                          temp_calibration.push({ total: index, text: "" });

                        }

                        setState((prev: any) => ({ ...prev, calibrationArray: [...temp_calibration], total_mark: +total_mark }))
                      }
                    }
                    }
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  <span className="text-shadow">{state.total_mark}</span>
                ) : null}
                {state.mode === "gradingModeTeacher" ? (
                  <>
                    <span className="col-auto border-left ml-2">
                      Score :
                    </span>
                    <input
                      type="number"
                      min={0}
                      className="col form-control"
                      name=""
                      id=""
                      value={state.score}
                      onChange={(e) => {
                        let score = e.target.value
                        let updatedState = { ...state, score }
                        onSaveQuestion(updatedState)
                        setState((prev: any) => ({ ...prev, score: score }))
                      }
                      }
                    />
                  </>
                ) : state.mode === "gradingModeStudent" ? (
                  <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      Score :  {state.score}
                    </span>
                    <span>{state.score}</span>
                  </>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  Audio
                </div>
              </div>

          }







          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })); onHandleCount(); }}
                  onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a })); onHandleCount(); }}
                  defaultValue={state.header}
                  height={"10px"}
                ></RichBox>

              </div>
            ) : state.mode === "examMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <>
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: state.header }}

                ></div>
                <div className="mt-2 col-8 row justify-content-center">
                  {state.isRecording === true ? (<div className="ocean">
                    <div className="wave"></div>
                  </div>) : null}
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
            />

          ) : null}
          <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
              all_targets={state.all_targets?.length > 0 ? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets={(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}
          {state.mode === "gradingModeTeacher" ||
            state.mode === "gradingModeStudent" ? (
            <div className="col-12 my-3">
              <audio src={state.media_blob_url} controls preload="none" />
            </div>
          ) : null}


          {state.mode === "examMode" ? (
            <div className="col-12 row my-3 align-items-start ml-1">
              <ReactMediaRecorder
                audio
                render={({
                  startRecording,
                  stopRecording,
                  mediaBlobUrl: media_blob_url,
                  previewStream,
                }) => {
                  return (
                    <div className=" row">
                      <p className="col-12">{status}</p>
                      <div className="col-12">
                        {state.isRecording ? (
                          <AudioPreview stream={previewStream} />
                        ) : null}
                        {(state.media_blob_url || media_blob_url) && !state.isRecording && (
                          <>
                            <audio
                              src={state.isRecording == false ? media_blob_url ?? "" : state.media_blob_url}
                              controls
                              autoPlay
                            />
                            {/* <audio src={media_blob_url} controls autoPlay loop /> */}
                          </>
                        )}
                      </div>
                      <div className="col-12">

                        <>
                          <button
                            className="btn btn-primary text-white mx-1"
                            onClick={() => {
                              startRecording();
                              setState((prev: any) => ({ ...prev, isRecording: true }))
                            }}
                          >
                            Start Recording
                          </button>
                          <button
                            className="btn btn-warning text-white mx-1"
                            onClick={() => {
                              stopRecording();
                              setState((prev: any) => ({ ...prev, isRecording: false }))
                            }}
                          >
                            Stop Recording
                          </button>
                          <button
                            className="btn btn-warning text-white mx-1"
                            onClick={() => {
                              onSaveVideo(media_blob_url);
                            }}
                          >
                            Save and Submit
                          </button>
                        </>

                      </div>
                    </div>
                  );
                }}
              />
            </div>
          ) : null}



          {state.mode === "editingMode" ? (
            <div className="row col-12">

              <div className=" col-md-6 my-2"> Analytical Rubrics :
                <div className="  row  col-12 ">
                  {
                    state.calibrationArray?.sort((a: any, b: any) => b.total - a.total).map((cal: any, index: any) => {
                      let total = cal.total
                      if (total == 1 && props.etype == "capstone") {
                        return (
                          <div></div>)
                      }
                      return (
                        <div className="col-6 my-3" key={total} >
                          <label>The score will be {total}
                            {
                              props.etype == "capstone" ? (<span >  ({colors[index]})</span>) : ``
                            } if :
                          </label>
                          <textarea className="form-control" onChange={(e: any) => { handleCalibration(e, total) }}
                            value={state.calibrationArray?.find((cal: any) => cal.total == total)?.text ?? ""}
                          ></textarea>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className=" col-md-6 my-2"> Holistic Rubrics :
                <div className="  row  col-12 ">
                  {
                    <div className="col-12 my-3"  >
                      <label>
                      </label>
                      <textarea className="form-control" onChange={(e: any) => {
                        let value = e.target.value
                        let temp_calibration = state.calibration;
                        console.log(temp_calibration)
                        temp_calibration = {
                          Analytical: ""

                        }
                        temp_calibration["Holistic"] = value;
                        console.log(temp_calibration)
                        setState((prev: any) => ({ ...prev, calibration: temp_calibration }))
                      }}
                        value={state.calibration?.Holistic}
                      ></textarea>
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : ""}


          {state.mode === "gradingModeTeacher" ||
            state.mode === "gradingModeStudent" ? (
            <div className="col-12 mt-5 row align-items-start align-content-start mx-auto">
              <label className="align-self-start">Feedback</label>
              <textarea
                className="form-control align-self-stretch "
                name=""
                id=""
                value={state.feedback}
                rows={5}
                disabled={
                  state.mode === "gradingModeStudent" ? true : false
                }
                onChange={(e) => {
                  let feedback = e.target.value;
                  let updatedState = { ...state, feedback }
                  onSaveQuestion(updatedState)
                  setState((prev: any) => ({ ...prev, feedback: feedback }))
                }
                }
              ></textarea>
            </div>
          ) : null}
          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg rounded col-3 mt-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>
    </div>
  );


}
export default AudioComponent