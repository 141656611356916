import React, { ReactFragment, useEffect, useState } from "react";
import { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
//import { ApplicationState } from "../store";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import MCQ from "../questions/MCQComponent";
import TrueOrFalse from "../questions/TrueOrFalse";
import OrderComponent from "../questions/OrderComponent";
import MatchQComponent from "../questions/MatchQComponent";
import EssayComponent from "../questions/EssayComponent";
import AudioComponent from "../questions/AudioComponent";
import LabelQComponent from "../questions/LabelQComponent";
import ImageHighlightComponent from "../questions/ImageHighlightComponent";
import FileUploadEnhanced from "../questions/FileUploadEnhanced";
import FillTheBlank from "../questions/FillTheBlank";
import VideoComponent from "../questions/VideoComponent";
import "react-responsive-modal/styles.css";
import { makeId, objectsAreSame } from "../../helpers/func";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";

import ProcessCardSingleState from "../questions/ProcessCardSingleState";
import { MathJax } from "better-react-mathjax";


const  QuestionRender = (props :any) => {
 const [state , setState ] = useState<any>( {})
 
 
   useEffect(()=>{
    console.log(props.q)
       setState(props.q)
   },[props.q])
 




 


    //examMode
    //gradingModeTeacher
    //gradingModeStudent
   
    const editQuestionsRenderer = (s: any, q: any, index: any) => {
      q.mode = "gradingModeStudent";
      switch (s) {
        case "MCQ":
          return (
            <MCQ
              quest={q}
              index={index}              
            />
          );
          return null;
        case "OrderComponent":
          return (
            <OrderComponent
              quest={q}
              index={index}
              
              
            />
          );
          return null;
        case "MatchQComponent":
          return (
            <MatchQComponent
              quest={q}
              index={index}
              
              
            />
          );
          return null;
          case "ProcessCard":
            return (
              <ProcessCardSingleState
                type={"ProcessCard"}
                quest={q}
                index={index}
                
                
              />
            );
            return null;
            case "ProductCard":
              return (
                <ProcessCardSingleState
                  type={"ProductCard"}
                  quest={q}
                  index={index}
                  
                  
                />
              );
              return null;
        case "LabelQComponent":
          return (
            <LabelQComponent
              quest={q}
              index={index}
              
              
            />
          );
          return null;
        case "TrueOrFalse":
          return (
            <TrueOrFalse
              quest={q}
              index={index}
              
              
            />
          );
          return null;
        case "EssayComponent":
          return (
            <EssayComponent
              quest={q}
              index={index}
            />
          );
          return null;
        case "VideoComponent":
          return (
            <VideoComponent
              quest={q}
              index={index}        
            />
          );
          return null;
        case "AudioComponent":
          return (
            <AudioComponent
              quest={q}
              index={index}            
            />
          );
          return null;
        case "FileUploadEnhanced":
          return (
            <FileUploadEnhanced
              quest={q}
              index={index}           
            />
          );
          return null;
        case "FillTheBlank":
          return (
            <FillTheBlank
              quest={q}
              index={index}          
            />
          );
          return null;
        case "ImageHighlight":
          return (
            <ImageHighlightComponent
              quest={q}
              index={index}    
            />
          );
          return null;
        default:
          return null;
      }
    };


  

    return  (
      <MathJax>
      <div>
        <div className=" d-flex d-md-block flex-nowrap wrapper ">
          <div className="col-md-9 float-center mx-auto pl-md-2 pt-2 main">
            <div>
              <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                <div className="row"></div>
                
                <h3 className="text-shadow mt-2 ml-3">Questions : </h3>
               
                    <React.Fragment >
                      {editQuestionsRenderer(state.type, state , 1)}
                    </React.Fragment>
                
              </div>
            </div>
          </div>
        </div>
      </div></MathJax>
    ) ;
    // }
  }



export default QuestionRender