import React, { useEffect, useState } from "react";
import { NavLink, useHistory, RouteComponentProps, useParams } from "react-router-dom";
import $ from "jquery";

import axios from "axios";
import { BASE_URL } from "../../../variables/api";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";


interface StudentsExamsProps extends RouteComponentProps<{ search: string }> {
  count: number;
  searchexams?: string;
  studentsExams: Array<any>;
  subjects: Array<any>;
  GetStudentsExams: any;
  GetSubjects: any;
}

const StudentsExams = (props: any) => {
  const { exam_id }: any = useParams();
  const navigate = useHistory();
  const [studentsExams, setStudentsExams] = useState([])
  const [printModal, setPrintModal] = useState(false);
  const [printSettings, setPrintSettings] = useState({
    score: true,
    feedback: false
  });
  require("datatables.net");
  require("../../MyComponents/dataTables.bootstrap4.min");

  useEffect(() => {
    Swal.showLoading()
    axios.post(`${BASE_URL}admin/getstudentsexams.php`, { exam_id: exam_id }).then((res: any) => {
      $('#dataTable').DataTable().destroy();
      setStudentsExams(res.data.data.exams)
      Swal.close();
    }).catch(() => {
      Swal.close();
    })
  }, [])


  useEffect(() => {
    $('#dataTable').DataTable();
  }, [studentsExams]);

  const grading = (id: any) => {
    navigate.push({
      pathname: "/assessmentViewComponent",
      search: "?id=" + id + "&exammode=gradingModeTeacher",
    });
  };

  const handlePrintExams = (e:any) => {
    Swal.showLoading();
     e.preventDefault()
    axios.get(`${BASE_URL}admin/printexams/printexams.php?id=${exam_id}&showscore=${printSettings.score}&showfeedback=${printSettings.feedback}`).then((res: any) => {
     
    
        // The file URL you want to download
        const fileUrl =`${BASE_URL}admin/printexams/examsfiles/`+res.data.data;
    
        // Create a new anchor element
        const a = document.createElement('a');
        
        // Set the href attribute to the file URL
        a.href = fileUrl;
        
        // Set the download attribute (optional, to set a default filename)
        a.download = res.data.data;  // You can change the file name here
        
        // Append the anchor to the body (necessary for some browsers)
        document.body.appendChild(a);
        
        // Trigger a click event on the anchor to download the file
        a.click();
        
        // Remove the anchor from the DOM after the download starts
        document.body.removeChild(a);
        Swal.close();
      
    })
  }

  return (
    <div>
      {/* <button className="btn bg-info text-white mb-2" onClick = {this.addAssessmentHandler}>Add Assessment</button> */}
      <NavLink to={"/dashboard/assessments"} className="btn btn-secondary mb-3">
        <i className="fa fa-arrow-left" aria-hidden="true" />
      </NavLink>
      <div>
        <button onClick={() => { setPrintModal(true) }} className="btn btn-success mb-2">Print students exams</button>
        {
          <table
            id="dataTable"
            className="table-hover table-bordered text-center"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Exam name</th>
                <th>Subject</th>

                <th>Student Id</th>
                <th>Student name</th>
                <th>Group name</th>
                <th>Release date</th>
                <th>Submission date</th>
                <th>Status</th>
                <th>Grades</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {studentsExams?.map(
                (studentsExam: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{studentsExam.examname}</td>
                      <td>{studentsExam.subject}</td>
                      <td>{studentsExam.student_id}</td>
                      <td>{studentsExam.studentname}</td>
                      <td>{studentsExam.groupname}</td>
                      <td>{studentsExam.releasedate}</td>
                      <td>{studentsExam.submitdate}</td>

                      <td>{studentsExam.status}</td>


                      {studentsExam.status == "Pending" &&
                        studentsExam.published == 1 ? (
                        <>
                          <td> </td>
                          <td> </td>
                        </>
                      ) : studentsExam.status == "Submitted" ? (
                        <>
                          <td></td>
                          <td>
                            <button
                              className="btn bg-info text-white mb-2"
                              onClick={() => {
                                grading(studentsExam.id);
                              }}
                            >
                              Grading
                            </button>{" "}
                            <span> </span>{" "}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{`${studentsExam.total_score} / ${studentsExam.outof}`}</td>
                          <td>
                            <button
                              className="btn bg-info text-white mb-2"
                              onClick={() => {
                                grading(studentsExam.id);
                              }}
                            >
                              View
                            </button>{" "}
                            <span> </span>{" "}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        }
      </div>


      <Modal

        classNames={{
          modal: "customModal_2"
        }}
        open={printModal}
        onClose={() => { setPrintModal(false); }}
        center
      >

        <form onSubmit={handlePrintExams}>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="score" name="score" value="score"
              onChange={(e: any) => {
                let score = e.target.checked
                setPrintSettings((prev: any) => ({ ...prev, score: score }))
              }}
              checked={printSettings.score} />
            <label className="form-check-label" htmlFor="score">Show student scores in exam</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="feedback" name="feedback" value="feedback"
              onChange={(e: any) => {
                let feedback = e.target.checked
                setPrintSettings((prev: any) => ({ ...prev, feedback: feedback }))
              }}
              checked={printSettings.feedback} />
            <label className="form-check-label" htmlFor="feedback">Show feedback</label>
          </div>

          <div className="text-center">

            <button type="submit" className="btn btn-success " >Download file</button>
          </div>
        </form>


      </Modal>
    </div>
  );

}
export default StudentsExams

