import React, { ReactFragment } from "react";
import { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { actionCreators as Login } from '../store/Login';
//import { ApplicationState } from "../store";
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import { actionCreators as AssessmentActionCreator } from "../../store/Assessment";
import MCQ from "../questions/MCQComponent";
import TrueOrFalse from "../questions/TrueOrFalse";
import OrderComponent from "../questions/OrderComponent";
import MatchQComponent from "../questions/MatchQComponent";
import EssayComponent from "../questions/EssayComponent";
import AudioComponent from "../questions/AudioComponent";
import LabelQComponent from "../questions/LabelQComponent";
import ImageHighlightComponent from "../questions/ImageHighlightComponent";
import FileUploadEnhanced from "../questions/FileUploadEnhanced";
import FillTheBlank from "../questions/FillTheBlank";
import VideoComponent from "../questions/VideoComponent";
import "react-responsive-modal/styles.css";
import { makeId, objectsAreSame } from "../../helpers/func";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";

import ProcessCardSingleState from "../questions/ProcessCardSingleState";
import { MathJax } from "better-react-mathjax";
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  onSaveQuestionHandler: (data: any) =>
    dispatch(AssessmentActionCreator.saveQuestion(data)),
  AddAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.AddAssessment(data)),
  StudentSubmiteExam: (data: any) =>
    dispatch(AssessmentActionCreator.StudentSubmiteExam(data)),
  resetAssessment: () => dispatch(AssessmentActionCreator.resetAssessment()),
  UpdateGradingAssessment: (data: any) =>
    dispatch(AssessmentActionCreator.UpdateGradingAssessment(data)),
  GradingAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GradingAssessment(id)),
  GetGradedExam: (id: any) =>
    dispatch(AssessmentActionCreator.GetGradedExam(id)),
  GetAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.GetAssessment(id)),
  ViewAssessment: (id: any) =>
    dispatch(AssessmentActionCreator.ViewAssessment(id)),
  resetGotData: () => dispatch(AssessmentActionCreator.resetGotData()),
});

class assessmentComponent extends Component<any, any> {
  state = {
    id: makeId(10),
    name: "",
    grade: "",
    subject_id: "",
    startdate: "",
    enddate: "",
    nooftrials: 1,
    published: false,
    grading_teachers_ids: {},
    editing_teachers_ids: {},

    questions: [],
    open: false,
    selectedgroups: [],
    weeknumber: 1,
    istimed: 0,
    issecured: 0,
    duration: 0,
  };
  modalContent: any = null;
  encryptedMode = this.props.location.search.split("&")[1].split("=")[1];

  examMode =
    this.encryptedMode == "MSTMG" ? "gradingModeStudent" : this.encryptedMode;

  componentDidMount() {

    $("#preloader").hide();
    this.props.resetGotData();
    let id = this.props.location.search.split("&")[0].split("=")[1];
    this.examMode == "gradingModeTeacher"
      ? this.props.GradingAssessment(id)
      : this.examMode == "gradingModeStudent"
        ? this.props.GetGradedExam(id)
        : this.props.ViewAssessment(id);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.viewdata === false) {
      return;
    } else {
      const assessment = this.props.assessment
      if (assessment !== null && !objectsAreSame(prevState.assessment, assessment)) {
        this.setState({
          id: assessment.id,
          questions: assessment.answerdquestions,
        })
        $("#preloader").hide();
        Swal.close();
      } else if (objectsAreSame(prevState.assessment, assessment)) {

      }
      // $("#preloader").hide();
      //     Swal.close();
    }
  }

  onCloseModal = (Q: any = null) => {
    // save q in array in the store

    this.setState({ open: false });
  };
  onSaveQuestion = (q: any) => {

    this.props.onSaveQuestionHandler(q);
  };

  submitExam = () => {
    const data = {
      id: this.props.assessment.id,

      questions: this.props.questions,
    };

    this.props.UpdateGradingAssessment(data);
  };
  removeQuest = (e: any) => {
    this.props.DeleteQuestion(e.id);
  };

  handleBackClick = (id: any) => {
    this.props.resetAssessment();
    if (id === undefined) {
      this.props.history.push({
        pathname: `/dashboard/assessments`
      });
    } else {
      this.props.history.push({
        pathname: `/dashboard/StudentsExams/${id}`
      });
    }
  }
  render() {

    //examMode
    //gradingModeTeacher
    //gradingModeStudent
    if (this.props.viewdata === false) {
      return <p className="text-center">Loading ...</p>;
    }
    const editQuestionsRenderer = (s: any, q: any, index: any) => {
      q.mode = this.examMode;
      switch (s) {
        case "MCQ":
          return (
            <MCQ
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "OrderComponent":
          return (
            <OrderComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "MatchQComponent":
          return (
            <MatchQComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
          case "ProcessCard":
            return (
              <ProcessCardSingleState
                type={"ProcessCard"}
                quest={q}
                index={index}
                onCloseModal={() => this.onCloseModal()}
                onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
              />
            );
            return null;
            case "ProductCard":
              return (
                <ProcessCardSingleState
                  type={"ProductCard"}
                  quest={q}
                  index={index}
                  onCloseModal={() => this.onCloseModal()}
                  onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
                />
              );
              return null;
        case "LabelQComponent":
          return (
            <LabelQComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "TrueOrFalse":
          return (
            <TrueOrFalse
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "EssayComponent":
          return (
            <EssayComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "VideoComponent":
          return (
            <VideoComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "AudioComponent":
          return (
            <AudioComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "FileUploadEnhanced":
          return (
            <FileUploadEnhanced
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "FillTheBlank":
          return (
            <FillTheBlank
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        case "ImageHighlight":
          return (
            <ImageHighlightComponent
              quest={q}
              index={index}
              onCloseModal={() => this.onCloseModal()}
              onSaveQuestion={(q: any) => this.onSaveQuestion(q)}
            />
          );
          return null;
        default:
          return null;
      }
    };

    // const examsArr = this.props.questions?.map((q: any, index: number) => {
    //   return (
    //     <React.Fragment key={index}>
    //       {editQuestionsRenderer(q.type, q, index + 1)}
    //     </React.Fragment>
    //   );
    // })
    //this.props.questions.find
    const onConfirm = (exam_id: any) => {
      this.props.resetAssessment();
      if (this.examMode == "examMode") {
        this.props.history.push("/dashboard/assessments");
      } else if (this.examMode == "gradingModeTeacher") {
        this.props.history.push({
          pathname: `/dashboard/StudentsExams/${exam_id}`
        });
      }
    };

    let MSG = null;
    if (this.props.assessmentMsg != "" && this.props.assessmentMsg != undefined) {
      Swal.fire({
        title: "sucess",
        text: this.props.assessmentMsg,
      }).then(() => {

        onConfirm(this.props.assessment.exam_id)
      })
    }

  

    return this.props.addAssessmentloading != true ? (
      <MathJax>
      <div>
        <div className=" d-flex d-md-block flex-nowrap wrapper ">
          <div className="col-md-9 float-center mx-auto pl-md-2 pt-2 main">
            <div>
              <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                <div className="row"></div>
                <button onClick={() => { this.handleBackClick(this.props.assessment.exam_id) }} className="btn btn-secondary mb-3">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <div className="mt-4 ml-3">

                </div>

                <div id="printableDiv">

                <h3 className="text-shadow mt-2 ml-3">Questions : </h3>
                {this.props.questions?.map((q: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {editQuestionsRenderer(q.type, q, index + 1)}
                    </React.Fragment>
                  );
                })}
                </div>
                {this.examMode == "gradingModeTeacher" && (
                  <div className="text-center mt-5 ">
                    <button
                      className="btn btn-warning text-white"
                      onClick={this.submitExam}
                    >
                      Save Grading
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div></MathJax>
    ) : (<MathJax>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
      </div></MathJax>
    );
    // }
  }
}
function mapStateToProps(state: any) {
  return {
    questions: state.Assessment.questions,
    assessment: state.Assessment.assessment,
    loading: state.Assessment.loading,
    assessmentMsg: state.Assessment.assessmentMsg,
    addAssessmentloading: state.Assessment.addAssessmentloading,
    grades: state.Assessment.grades,
    subjects: state.Assessment.subjects,
    viewdata: state.Assessment.viewdata
  };
}

export default withRouter(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(assessmentComponent as any)
);
